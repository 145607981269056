import { Random } from './random'

export type TPoint = number
export type TRandom = Random
export type TEnd = { score: number; winner: TPlayer }
export type TPlayer = 'X' | 'O'
export type TRoll = number[]
export type TMove = {
	source: TPoint | 'jail'
	target: TPoint | 'free'
	roll: number
	killer: boolean
}

export type MCTSNode = { n: number; cum: number }

const ArrayCtor = Int8Array
type TArray = InstanceType<typeof ArrayCtor>

export class MCTSBook {
	totalEntries = 0
	book: Map<string, MCTSNode> = new Map()
	record(gammon: BackGammon, result: TEnd) {
		this.totalEntries++
		const serialized = gammon.serialize()

		let prior = this.book.get(serialized)
		if (!prior) {
			prior = { n: 0, cum: 0 }
			this.book.set(serialized, prior)
		}
		if (result.winner === gammon.board.player) {
			prior.n++
			prior.cum += result.score
		} else {
			prior.n++
			prior.cum -= result.score
		}
	}

	consult(gammon: Gammon): MCTSNode {
		return this.book.get(gammon.serialize()) ?? { n: 0, cum: 0 }
	}
}

export const randomInt = (max: number, seed: TRandom) => seed.int({ max })
export const random0To1 = (seed: TRandom) => seed.float()

export interface Gammon {
	listNext(roll: TRoll): this[]
	listRolls(): TRoll[]

	getEndState(): TEnd | undefined

	serialize(): string
	clone(): Gammon

	flipped(): Gammon
}

export class BackGammon implements Gammon {
	[Symbol.toStringTag]() {
		return this.serialize()
	}

	constructor(
		public readonly board: Readonly<BoardState>,
		flippedFrom?: BackGammon,
	) {
		if (flippedFrom) {
			this._flipped = flippedFrom
		}
	}

	listNext(roll: TRoll): this[] {
		if (roll[0] < roll[1]) {
			roll = [roll[1], roll[0]]
		}
		return NextStates({ board: this.board, rolls: roll }).map(
			(s) => new BackGammon(s),
		) as this[]
	}

	listRolls(): TRoll[] {
		const d6 = [1, 2, 3, 4, 5, 6]
		const rolls: number[][] = []
		for (const a of d6) {
			for (const b of d6) {
				if (a === b) {
					rolls.push([a, a, a, a])
				} else if (a > b) {
					rolls.push([a, b])
				} else {
					rolls.push([b, a])
				}
			}
		}
		return rolls
	}

	getEndState(): TEnd | undefined {
		const isMars = (board: BoardState): boolean => {
			return board.freeTu === 0
		}
		const isBackgammon = (board: BoardState): boolean => {
			return (
				board.freeTu === 0 &&
				board.points.some((v, i) => i < board.runway && v < 0)
			)
		}
		for (const board of [this.board, this.flipped().board]) {
			if (CountPips(board) === 0) {
				const score =
					(isBackgammon(board) ? 3 : isMars(board) ? 2 : 1) *
					doublingCubeValue(board)

				return { winner: board.player, score: score }
			}
		}
	}

	private _serialized: string | undefined = undefined
	serialize(): string {
		if (!this._serialized) {
			const freeYo = this.board.freeYo ? '' + this.board.freeYo : ''
			const freeTu = this.board.freeTu ? '-' + this.board.freeTu : ''
			this._serialized = [freeYo, ...this.board.points, freeTu]
				.map((v, i) => (v ? `${i}:${v}` : ''))
				.filter((x) => x)
				.join(' ')
		}
		return this._serialized
	}

	private _flipped: BackGammon | undefined
	flipped(): BackGammon {
		if (!this._flipped) {
			this._flipped = new BackGammon(FlipBoard(this.board), this)
		}
		return this._flipped
	}

	clone(): this {
		return new BackGammon(CloneBoard(this.board)) as typeof this
	}

	private _isEndgame: boolean | undefined
	isEndgame(): boolean {
		if (this._isEndgame === undefined) {
			const noJail = this.board.jailTu === 0 && this.board.jailYo === 0
			const firstEnemy = this.board.points.findIndex((v) => v < 0)
			const allOursBeforeFirstEnemy = this.board.points
				.slice(firstEnemy)
				.every((v) => v <= 0)
			this._isEndgame = noJail && allOursBeforeFirstEnemy
		}
		return this._isEndgame
	}

	runMCTS(
		seed: TRandom,
		rounds: number,
		book: MCTSBook | undefined,
		selectViaHeuristic: (boards: BackGammon[], seed: TRandom) => this,
		onVictory?: (endState: TEnd) => void,
		initialRoll?: TRoll,
	) {
		const potentialRolls = this.listRolls()

		const MCTSDescend = (start: BackGammon): void => {
			const chain = [start]
			let gas = 1000
			while (gas && !chain[0].getEndState()) {
				gas--
				const roll =
					(chain.length === 1 && initialRoll) ||
					potentialRolls[randomInt(potentialRolls.length, seed)]

				const workingCopy = chain[0]
				const next = workingCopy.listNext(roll)

				const child = selectViaHeuristic(next, seed)

				chain.unshift(child.flipped())
			}
			if (!gas) {
				console.log('outta gas')
			}

			const endState = chain[0].flipped().getEndState()
			if (endState) {
				onVictory?.(endState)
				chain.forEach((g) => {
					book?.record(g.flipped(), endState)
				})
			}
		}

		for (let round = 0; round < rounds; round++) {
			MCTSDescend(this)
		}
	}
}

export type BoardState = {
	points: TArray
	runway: number
	player: TPlayer

	jailYo: TPoint
	jailTu: TPoint

	freeYo: TPoint
	freeTu: TPoint

	doublingCube: number
}

export type GameState = {
	board: BoardState
	rolls: TRoll
}

export type MatchState = {
	cubeEnabled: boolean
	pointsX: number
	pointsO: number
	playingUntil: number
}

const d6 = [1, 2, 3, 4, 5, 6]

export const CloneBoard = (board: BoardState): BoardState => ({
	...board,
	points: new ArrayCtor(board.points),
})

export const FlipBoard = (board: BoardState): BoardState => ({
	jailTu: board.jailYo,
	runway: board.runway,
	jailYo: board.jailTu,
	freeYo: board.freeTu,
	freeTu: board.freeYo,
	player: board.player === 'X' ? 'O' : 'X',
	doublingCube: -board.doublingCube,
	points: new ArrayCtor(board.points).reverse().map((p) => -p || p),
})

export const Board = (
	board?: Partial<Omit<BoardState, 'points'>> & {
		points?: number[] | TArray
	},
): BoardState => ({
	jailYo: 0,
	jailTu: 0,
	freeYo: 0,
	freeTu: 0,
	runway: board?.runway ?? Math.ceil((board?.points?.length || 24) / 4),
	player: 'X',
	doublingCube: 0,
	...board,
	points: new ArrayCtor(
		board?.points ?? [
			// -2, 0, 0, 0, 0, 0,
			// 0, 0, 0, 0, 0, +1,
			// -1, 0, 0, 0, 0, 0,
			// 0, 0, 0, 0, 0, +2,

			-2, 0, 0, 0, 0, +5, 0, +3, 0, 0, 0, -5, +5, 0, 0, 0, -3, 0, -5, 0, 0, 0,
			0, +2,
		],
	),
})

export const SerializeBoard = (
	board: BoardState,
	includePlayer = false,
): string => {
	const length = board.points.length === 24 ? '' : board.points.length
	const player = includePlayer ? board.player : ''
	const free =
		board.freeYo || board.freeTu ? `f${board.freeYo}-${board.freeTu}` : ''
	const jail =
		board.jailYo || board.jailTu ? `j${board.jailYo}-${board.jailTu}` : ''
	const doublingCube = board.doublingCube ? `d${board.doublingCube}` : ''
	const points =
		'p' +
		[...board.points]
			.map((v, i) => (v ? `${i + 1}.${v}` : ''))
			.filter((x) => x)
			.join(' ') +
		'p'
	return [length, player, points, free, jail, doublingCube]
		.filter((x) => x)
		.join(' ')
}

export const DeserializeBoard = (src: string): BoardState => {
	const board = Board()

	const player = src.match('X|O')
	if (player) {
		board.player = player[0] as TPlayer
	}

	const length = src.match(/^(\d+)/)
	if (length) {
		const l = parseInt(length[1]) || 24
		board.points = new ArrayCtor(l)
		board.runway = Math.ceil(board.points.length / 4)
	}

	const free = src.match(/f(\d+)-(\d+)/)
	if (free) {
		const [, freeYo, freeTu] = free
		board.freeYo = parseInt(freeYo) || 0
		board.freeTu = parseInt(freeTu) || 0
	}

	const jail = src.match(/j(\d+)-(\d+)/)
	if (jail) {
		const [, jailYo, jailTu] = jail
		board.jailYo = parseInt(jailYo) || 0
		board.jailTu = parseInt(jailTu) || 0
	}

	const doubling = src.match(/d(-?\d+)/)
	if (doubling) {
		const [, value] = doubling
		board.doublingCube = parseInt(value)
	}

	const points = src.match(/p([^p]*)p/)
	if (points) {
		board.points.fill(0)
		const parsed = points[1].split(' ').map((kv) => kv.split('.'))
		for (const [i, v] of parsed) {
			const index = parseInt(i)
			const value = parseInt(v)
			if (index) {
				board.points[index - 1] = value
			}
		}
	}

	return board
}

export const SerializeGame = (game: GameState): string => {
	const board = SerializeBoard(game.board, true)
	const rolls = game.rolls.join('-')
	return `${board}_${rolls}`
}

export const DeserializeGame = (src: string): GameState => {
	const [boardString, rollString] = src.split('_')
	const rolls = (rollString || '')
		.split('-')
		.map((d) => parseInt(d))
		.filter((d) => !isNaN(d))
	const board = DeserializeBoard(boardString)
	return { rolls, board }
}

export const CountPips = (board: BoardState): number => {
	let pips = 0
	board.points.forEach((p, i) => {
		if (p > 0) {
			pips += p * (i + 1)
		}
	})
	pips += board.jailYo * (board.points.length + 1)
	return pips
}

const AllNextStates = (
	current: GameState,
	existingMoves: TMove[],
	consumed: number,
	allMoveOrders: boolean,
	seen: Set<string> = new Set(),
): {
	state: GameState
	moves: TMove[]
	consumed: number
}[] => {
	if (current.rolls.length === 0) {
		return [{ state: current, moves: existingMoves, consumed }]
	}

	const next: { state: GameState; moves: TMove[]; consumed: number }[] = []
	const board = current.board
	const roll = current.rolls[0]

	const occupiedPoints: { point: number; index: number }[] = []

	board.points.forEach((point, index) => {
		if (point > 0) {
			occupiedPoints.push({ point, index })
		}
	})

	if (occupiedPoints.length === 0 && board.jailYo === 0) {
		return [{ state: current, moves: existingMoves, consumed }]
	}

	const farthestPoint = board.jailYo
		? board.points.length
		: occupiedPoints[occupiedPoints.length - 1].index

	const applyMove = (source: number | 'jail', _target: number) => {
		const target = _target < 0 ? 'free' : _target
		const isOvershoot = _target < -1

		if (target === 'free' && farthestPoint >= board.runway) {
			return
		}
		if (isOvershoot && source !== farthestPoint) {
			return
		}
		if (board.jailYo && source !== 'jail') {
			return
		}

		if (target === 'free' || board.points[target] > -2) {
			const newBoard = CloneBoard(board)
			let killer = false
			if (target === 'free') {
				newBoard.freeYo++
			} else {
				if (newBoard.points[target] === -1) {
					newBoard.jailTu++
					killer = true
					newBoard.points[target] = 1
				} else {
					newBoard.points[target]++
				}
			}

			if (source === 'jail') {
				newBoard.jailYo--
			} else {
				newBoard.points[source]--
			}

			const effectiveSource = source === 'jail' ? board.points.length : source
			const effectiveTarget = target === 'free' ? -1 : target
			next.push({
				state: {
					board: newBoard,
					rolls: current.rolls.slice(1),
				},
				moves: [{ source, target, roll, killer }],
				consumed: effectiveSource - effectiveTarget,
			})
		}
	}

	if (current.board.jailYo) {
		const source = board.points.length
		const target = source - roll
		applyMove('jail', target)
	} else {
		for (const { index } of occupiedPoints) {
			applyMove(index, index - roll)
		}
	}

	if (current.rolls.length > 1 && roll > current.rolls[1]) {
		next.push({
			state: {
				board: current.board,
				rolls: [...current.rolls.slice(1), roll],
			},
			moves: [],
			consumed: 0,
		})
	}

	if (next.length === 0) {
		next.push({
			state: { board: current.board, rolls: current.rolls.slice(1) },
			moves: [],
			consumed: 0,
		})
	}

	const toReturn: {
		state: GameState
		moves: TMove[]
		consumed: number
	}[] = []

	// const seen = new Set()
	for (const n of next) {
		if (allMoveOrders) {
			toReturn.push(
				...AllNextStates(
					n.state,
					[...existingMoves, ...n.moves],
					n.consumed + consumed,
					allMoveOrders,
				),
			)
		} else {
			const serialized =
				SerializeBoard(n.state.board) + ';' + n.state.rolls.join(' ')
			if (!seen.has(serialized)) {
				seen.add(serialized)
				toReturn.push(
					...AllNextStates(
						n.state,
						[...existingMoves, ...n.moves],
						n.consumed + consumed,
						allMoveOrders,
						seen,
					),
				)
			}
		}
	}
	return toReturn
}

export const NextMoves = (
	current: GameState,
	allMoveOrders: boolean,
): { state: GameState; moves: TMove[] }[] => {
	const sortedRolls = [...current.rolls].sort((a, b) => b - a)
	current = { board: current.board, rolls: sortedRolls }

	if (!current.rolls.every((v, i) => i === 0 || v <= current.rolls[i - 1])) {
		throw Error('rolls out of order')
	}

	const all = AllNextStates(current, [], 0, allMoveOrders)
	let maxConsumption = 0
	let maxConsumers: { state: GameState; moves: TMove[] }[] = []
	for (const state of all) {
		if (state.consumed > maxConsumption) {
			maxConsumption = state.consumed
			maxConsumers = []
		}
		if (state.consumed === maxConsumption) {
			maxConsumers.push({ state: state.state, moves: state.moves })
		}
	}

	return maxConsumers
}

export const SerializeMove = (m: TMove): string =>
	`${m.source} => ${m.target} (${m.roll})`

export const NextRolls = (
	current: GameState,
): { board: BoardState; moves: TMove[] }[] => {
	return NextMoves(current, true).map(({ state, moves }) => ({
		board: state.board,
		moves,
	}))
}

export const NextStates = (current: GameState): BoardState[] => {
	return NextMoves(current, false).map(({ state }) => state.board)
}

export const GameOver = (current: BoardState) => {
	return CountPips(current) === 0
}

export const ApplyMove = (
	state: GameState,
	move: TMove,
): GameState | undefined => {
	const validMoves = NextRolls(state)
	const isValid = validMoves.some((validMove) => {
		const validNextMove = validMove.moves[0]
		return (
			validNextMove &&
			validNextMove.roll === move.roll &&
			validNextMove.source === move.source &&
			validNextMove.target === move.target
		)
	})

	if (!isValid) {
		return undefined
	}

	const newRolls = [...state.rolls]
	const rollUsed = newRolls.findIndex((r) => r === move.roll)
	newRolls.splice(rollUsed, 1)

	const newGame: GameState = {
		board: CloneBoard(state.board),
		rolls: newRolls,
	}
	if (move.source === 'jail') {
		newGame.board.jailYo--
	} else {
		newGame.board.points[move.source]--
	}

	if (move.target === 'free') {
		newGame.board.freeYo++
	} else {
		const existing = newGame.board.points[move.target]
		if (existing < 0) {
			newGame.board.jailTu++
			newGame.board.points[move.target] = 1
		} else {
			newGame.board.points[move.target]++
		}
	}

	return newGame
}

export const doublingCubeValue = (board: BoardState) => {
	return 2 ** Math.abs(board.doublingCube)
}

let count = 0
export const MiniMax = (
	board: BoardState,
	rolls: number[],
	depth: number,
	maximizing = true,
): number => {
	if (depth === 0) {
		count++
		if (count % 10000 === 0) {
			console.log(count)
		}
		const heuristic = CountPips(FlipBoard(board)) - CountPips(board)
		return heuristic
	} else {
		if (rolls.length === 0) {
			let avgVal = 0
			for (const d1 of d6) {
				for (const d2 of d6) {
					if (d2 < d1) {
						continue
					}
					let rolls: number[]
					if (d1 === d2) {
						rolls = [d1, d1, d1, d1]
						avgVal += (1 / 36) * MiniMax(board, rolls, depth, maximizing)
					} else {
						rolls = [d2, d1]
						avgVal += (2 / 36) * MiniMax(board, rolls, depth, maximizing)
					}
				}
			}
			return avgVal
		} else {
			if (maximizing) {
				const next = NextStates({ board, rolls })

				let max = -Infinity
				for (const n of next) {
					max = Math.max(
						max,
						-MiniMax(FlipBoard(n), [], depth - 1, !maximizing),
					)
				}
				console.log(
					'recursed:',
					next.length,
					'total next states',
					next.map((n) => n.points.join()),
				)

				return max
			} else {
				const next = NextStates({ board, rolls })
				let min = Infinity
				for (const n of next) {
					min = Math.min(
						min,
						-MiniMax(FlipBoard(n), [], depth - 1, !maximizing),
					)
				}

				return min
			}
		}
	}
}

export const BestMove = (state: GameState, depth: number): BoardState => {
	const c: GameState = { board: state.board, rolls: [...state.rolls] }
	const next = NextStates(c)
	console.log(
		'top level: ',
		next.length,
		'total next states',
		next.map((n) => n.points.join()),
	)
	let bestH = -Infinity
	let bestB = next[0]
	for (const n of next) {
		const heuristic = MiniMax(n, [], depth)
		// console.log({ heuristic, n })
		if (heuristic > bestH) {
			bestH = heuristic
			bestB = n
		}
	}
	return bestB
}

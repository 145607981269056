import * as React from 'react'

// CSS Icons https://css.gg/ | Astrit
export function IconUndo(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
			<path
				fill="currentColor"
				d="M5.34 4.468h2v2.557a7 7 0 11-1.037 10.011l1.619-1.185a5 5 0 10.826-7.384h2.591v2h-6v-6z"
			/>
		</svg>
	)
}

// Material Design Icons https://materialdesignicons.com/ | Austin Andrews
export function IconHistory(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M13.5 8H12v5l4.28 2.54.72-1.21-3.5-2.08V8M13 3a9 9 0 00-9 9H1l3.96 4.03L9 12H6a7 7 0 017-7 7 7 0 017 7 7 7 0 01-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.896 8.896 0 0013 21a9 9 0 009-9 9 9 0 00-9-9" />
		</svg>
	)
}

export function IconRobot(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M12 2a2 2 0 012 2c0 .74-.4 1.39-1 1.73V7h1a7 7 0 017 7h1a1 1 0 011 1v3a1 1 0 01-1 1h-1v1a2 2 0 01-2 2H5a2 2 0 01-2-2v-1H2a1 1 0 01-1-1v-3a1 1 0 011-1h1a7 7 0 017-7h1V5.73c-.6-.34-1-.99-1-1.73a2 2 0 012-2M7.5 13A2.5 2.5 0 005 15.5 2.5 2.5 0 007.5 18a2.5 2.5 0 002.5-2.5A2.5 2.5 0 007.5 13m9 0a2.5 2.5 0 00-2.5 2.5 2.5 2.5 0 002.5 2.5 2.5 2.5 0 002.5-2.5 2.5 2.5 0 00-2.5-2.5z" />
		</svg>
	)
}

export function IconRobotHappy(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M22 14h-1c0-3.87-3.13-7-7-7h-1V5.73A2 2 0 1010 4c0 .74.4 1.39 1 1.73V7h-1c-3.87 0-7 3.13-7 7H2c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1v1a2 2 0 002 2h14c1.11 0 2-.89 2-2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1M9.79 16.5C9.4 15.62 8.53 15 7.5 15s-1.9.62-2.29 1.5c-.13-.31-.21-.64-.21-1a2.5 2.5 0 015 0c0 .36-.08.69-.21 1m9 0c-.39-.88-1.29-1.5-2.29-1.5s-1.9.62-2.29 1.5c-.13-.31-.21-.64-.21-1a2.5 2.5 0 015 0c0 .36-.08.69-.21 1z" />
		</svg>
	)
}

export function IconRobotConfused(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M20 4h-2V3h2.5c.28 0 .5.22.5.5v2c0 .28-.22.5-.5.5H20v1h-1V5h1V4m-1 5h1V8h-1v1m-2-6h-1v4h1V3m6 12v3c0 .55-.45 1-1 1h-1v1c0 1.11-.89 2-2 2H5a2 2 0 01-2-2v-1H2c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1c0-3.87 3.13-7 7-7h1V5.73c-.6-.34-1-.99-1-1.73 0-1.1.9-2 2-2s2 .9 2 2c0 .74-.4 1.39-1 1.73V7h1c.34 0 .67.03 1 .08V10h4.74A6.94 6.94 0 0121 14h1c.55 0 1 .45 1 1m-13 .5a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0m9 0a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0M17 8h-1v1h1V8z" />
		</svg>
	)
}

export function IconRobotAngry(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M22 14h-1c0-3.87-3.13-7-7-7h-1V5.73A2 2 0 1010 4c0 .74.4 1.39 1 1.73V7h-1c-3.87 0-7 3.13-7 7H2c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1v1a2 2 0 002 2h14c1.11 0 2-.89 2-2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1M7.5 18A2.5 2.5 0 015 15.5c0-.82.4-1.54 1-2l3.83 2.88C9.5 17.32 8.57 18 7.5 18m9 0c-1.07 0-2-.68-2.33-1.62L18 13.5c.6.46 1 1.18 1 2a2.5 2.5 0 01-2.5 2.5z" />
		</svg>
	)
}

export function IconRobotDead(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M22 14h-1c0-3.87-3.13-7-7-7h-1V5.73A2 2 0 1010 4c0 .74.4 1.39 1 1.73V7h-1c-3.87 0-7 3.13-7 7H2c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1v1a2 2 0 002 2h14c1.11 0 2-.89 2-2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1M9.86 16.68l-1.18 1.18-1.18-1.18-1.18 1.18-1.18-1.18 1.18-1.18-1.18-1.18 1.18-1.18 1.18 1.18 1.18-1.18 1.18 1.18-1.18 1.18 1.18 1.18m9 0l-1.18 1.18-1.18-1.18-1.18 1.18-1.18-1.18 1.18-1.18-1.18-1.18 1.18-1.18 1.18 1.18 1.18-1.18 1.18 1.18-1.18 1.18 1.18 1.18z" />
		</svg>
	)
}

export function IconRobotLove(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M22 14h-1c0-3.87-3.13-7-7-7h-1V5.73A2 2 0 1010 4c0 .74.4 1.39 1 1.73V7h-1c-3.87 0-7 3.13-7 7H2c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1v1a2 2 0 002 2h14c1.11 0 2-.89 2-2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1M9.7 15.45l-.73.73-1.47 1.47-2.2-2.2c-.4-.4-.4-1.06 0-1.45.41-.42 1.06-.42 1.47 0l.73.72.73-.72c.41-.42 1.06-.42 1.47 0 .4.39.4 1.05 0 1.45m9 0l-.73.73-1.47 1.47-2.2-2.2c-.4-.4-.4-1.06 0-1.45.41-.42 1.06-.42 1.47 0l.73.72.73-.72c.41-.42 1.06-.42 1.47 0 .4.39.4 1.05 0 1.45z" />
		</svg>
	)
}

export function IconRobotExcited(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M22 14h-1c0-3.87-3.13-7-7-7h-1V5.73A2 2 0 1010 4c0 .74.4 1.39 1 1.73V7h-1c-3.87 0-7 3.13-7 7H2c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h1v1a2 2 0 002 2h14c1.11 0 2-.89 2-2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1M8.68 17.04L7.5 15.86l-1.18 1.18-1.18-1.18L7.5 13.5l2.36 2.36-1.18 1.18m9 0l-1.18-1.18-1.18 1.18-1.18-1.18 2.36-2.36 2.36 2.36-1.18 1.18z" />
		</svg>
	)
}

export function IconRobotOff(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			viewBox="0 0 24 24"
			fill="currentColor"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M23 15v3c0 .5-.36.88-.83.97l-3.2-3.2c.03-.09.03-.18.03-.27a2.5 2.5 0 00-2.5-2.5c-.09 0-.18 0-.27.03L10.2 7h.8V5.73c-.6-.34-1-.99-1-1.73 0-1.1.9-2 2-2s2 .9 2 2c0 .74-.4 1.39-1 1.73V7h1c3.87 0 7 3.13 7 7h1c.55 0 1 .45 1 1m-.89 6.46l-1.27 1.27-.95-.95c-.27.14-.57.22-.89.22H5a2 2 0 01-2-2v-1H2c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1c0-2.47 1.29-4.64 3.22-5.89L1.11 3l1.28-1.27 19.72 19.73M10 15.5a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0m6.07 2.46l-2.03-2.03c.19 1.04 1 1.84 2.03 2.03z" />
		</svg>
	)
}

// Feathericons https://feathericons.com/ | Cole Bemis
export function IconCheck(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M20 6L9 20l-5-5" />
		</svg>
	)
}

// Tabler Icons https://tablericons.com/ | Csaba Kissi
export function IconSettings(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			viewBox="0 0 24 24"
			height="1em"
			width="1em"
			{...props}
		>
			<path stroke="none" d="M0 0h24v24H0z" />
			<path d="M7 6 A2 2 0 0 1 5 8 A2 2 0 0 1 3 6 A2 2 0 0 1 7 6 z" />
			<path d="M14 6 A2 2 0 0 1 12 8 A2 2 0 0 1 10 6 A2 2 0 0 1 14 6 z" />
			<path d="M21 6 A2 2 0 0 1 19 8 A2 2 0 0 1 17 6 A2 2 0 0 1 21 6 z" />
			<path d="M7 18 A2 2 0 0 1 5 20 A2 2 0 0 1 3 18 A2 2 0 0 1 7 18 z" />
			<path d="M14 18 A2 2 0 0 1 12 20 A2 2 0 0 1 10 18 A2 2 0 0 1 14 18 z" />
			<path d="M5 8v8M12 8v8M19 8v2a2 2 0 01-2 2H5" />
		</svg>
	)
}

// Bootstrap https://icons.getbootstrap.com/ | Bootstrap
export function IconPlay(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			fill="currentColor"
			viewBox="0 0 16 16"
			height="1em"
			width="1em"
			{...props}
		>
			<path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 010 1.393z" />
		</svg>
	)
}

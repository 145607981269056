import React, { useEffect, useState } from 'react'
import { NextRolls, GameState, TMove, doublingCubeValue } from './Backgammon'
import Die from './Die'
import {
	IconCheck,
	IconHistory,
	IconPlay,
	IconSettings,
	IconUndo,
} from './icons'
import { Point, Checkers } from './Point'

const doublingCubeClasses = (game: GameState) => {
	if (game.board.doublingCube > 0) {
		return 'ours'
	} else if (game.board.doublingCube < 0) {
		return 'theirs'
	} else {
		return 'unset'
	}
}

export const Board: React.FC<{
	game: GameState
	readonly?: boolean
	hasRolled?: boolean
	onMove?: (move: TMove) => void
	movestring?: string
	message?: string
	score?: JSX.Element
	hasHistory?: boolean
	log?: (str: string) => void
	cubeEnabled?: boolean
	onCube?: () => void
	onRoll?: () => void
	onRevert?: () => void
	onSubmit?: () => void
	onSettings?: () => void
	onClearMovestring?: () => void
	togglePrevState?: (show: boolean) => void
	isAutoplay?: boolean
}> = (props) => {
	const [source, setSource] = useState<number | 'jail' | undefined>(undefined)

	const nextRolls = NextRolls(props.game)
	const cantMove = nextRolls.length === 1 && nextRolls[0].moves.length === 0
	nextRolls.sort((a, b) => a.moves[0].roll - b.moves[0].roll)
	const dests: (number | 'free')[] = []
	if (source !== undefined) {
		for (const roll of nextRolls) {
			const nextMove = roll.moves[0]
			if (nextMove && nextMove.source === source) {
				if (!dests.includes(nextMove.target)) {
					dests.push(nextMove.target)
				}
			}
		}
	}

	const logStr =
		dests.length === 0
			? 'no moves available'
			: source +
			  ' selected. Available destinations: ' +
			  dests.join(' ') +
			  ' press `minus` to move near or `equal` to move far'
	const { log } = props

	useEffect(() => {
		if (source) log?.(logStr)
	}, [source, logStr, log])

	const findNext = (source: number | 'jail') => {
		const moves: TMove[] = []
		const seen = new Set<string>()
		for (const roll of nextRolls) {
			const nextMove = roll.moves[0]
			if (nextMove) {
				const moveDescriptor = `${nextMove.source}/${nextMove.target}`
				if (
					nextMove &&
					nextMove.source === source &&
					!seen.has(moveDescriptor)
				) {
					moves.push(nextMove)
					seen.add(moveDescriptor)
				}
			}
		}
		return moves
	}

	const makeMove = (source: number | 'jail', target: number | 'free') => {
		if (props.readonly) {
			return
		}
		if (source !== undefined) {
			const availableMoves = findNext(source)
			const move = availableMoves.find(
				(m) => m.source === source && m.target === target,
			)
			if (move) {
				props.onMove?.(move)
			}
		}
		props.onClearMovestring?.()
		setSource(undefined)
	}

	const setSourceAndMaybeMove = (
		newSource: 'jail' | number,
		length?: 'short' | 'long',
	) => {
		if (props.readonly) {
			return
		}

		const availableMoves = findNext(newSource)
		availableMoves.sort((a, b) => a.roll - b.roll)
		const short = availableMoves[0]?.target
		const long = availableMoves[1]?.target

		if (availableMoves.length === 0) {
			setSource(undefined)
			props.onClearMovestring?.()
			return
		}

		if (newSource !== source) {
			setSource(newSource)
		}

		if (availableMoves.length === 1) {
			makeMove(newSource, availableMoves[0].target)
		} else if (length) {
			if (length === 'short') {
				makeMove(newSource, short)
			}
			if (length === 'long') {
				makeMove(newSource, long)
			}
		}
	}

	const jailed = props.game.board.jailYo !== 0
	const endgame =
		!jailed && props.game.board.points.every((p, i) => p <= 0 || i < 6)

	useEffect(() => {
		if (props.movestring) {
			const [, p, s, m] =
				/(?:(\d)(\d)?)?(-|=)?/.exec(props.movestring.trim()) ?? []

			const primary = +p
			const secondary = +s
			const move = m === '-' ? 'short' : m === '=' ? 'long' : undefined

			if (jailed) {
				setSourceAndMaybeMove('jail', move)
			} else if (primary) {
				if (endgame) {
					setSourceAndMaybeMove(primary - 1, move)
				} else {
					const quarter = props.game.board.points.length / 4
					const offset = quarter * (primary - 1)
					const relevantSlice = props.game.board.points.slice(
						offset,
						offset + quarter,
					)
					if (relevantSlice.filter((x) => x > 0).length === 1) {
						const occupied = relevantSlice.findIndex((x) => x > 0)
						setSourceAndMaybeMove(offset + occupied, move)
					} else if (secondary) {
						setSourceAndMaybeMove(offset + secondary - 1, move)
					}
				}
			} else if (move && source) {
				setSourceAndMaybeMove(source, move)
			}
		} else if (
			!props.readonly &&
			!props.isAutoplay &&
			props.game.rolls.length &&
			props.game.board.jailYo &&
			source === undefined
		) {
			selectJail(undefined, false)
		}
	})

	const selectJail = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
		autoMove = true,
	): void => {
		if (props.game.board.jailYo) {
			e?.stopPropagation()
			if (autoMove) {
				setSourceAndMaybeMove('jail')
			} else {
				setSource('jail')
			}
		}
	}

	const availableMoves = source ? findNext(source) : []
	availableMoves.sort((a, b) => a.roll - b.roll)
	const short = availableMoves[0]?.target
	const long = availableMoves[1]?.target

	const points = [...props.game.board.points].map((p, i) => {
		const quarter = props.game.board.points.length / 4
		const primary = Math.floor(i / quarter) + 1
		const offset = quarter * (primary - 1)
		const secondary = i - offset + 1

		const relevantSlice = props.game.board.points.slice(
			offset,
			offset + quarter,
		)
		const solo = relevantSlice.filter((x) => x > 0).length === 1

		const sourceToolTip = endgame
			? `[${secondary}]`
			: solo
			? `[${primary}]`
			: `[${primary} ${secondary}]`

		const destToolTip = short === i ? '[-]' : long === i ? '[=]' : ''

		return (
			<Point
				key={i}
				title={destToolTip || (p > 0 ? sourceToolTip : '')}
				player={props.game.board.player}
				isSelected={source === i}
				destType={i === short ? 'short' : i === long ? 'long' : undefined}
				onSelected={() => {
					if (p > 0 && source === undefined) {
						setSourceAndMaybeMove(i)
					} else if (source) {
						makeMove(source, i)
					} else {
						setSource(undefined)
						props.onClearMovestring?.()
					}
				}}
				index={i}
				point={p}
			></Point>
		)
	})

	const rail = buildRail(props, source, selectJail)

	return (
		<div
			className={'board player-' + props.game.board.player}
			onClick={() => {
				props.onClearMovestring?.()
				setSource(undefined)
			}}
		>
			<div className="quarter q1 xside">
				{points.slice(0, points.length / 4)}
			</div>
			<div className="quarter q2 xside">
				{points.slice(points.length / 4, points.length / 2)}
			</div>
			<div className="quarter q3 oside">
				{points.slice(points.length / 2, (points.length / 4) * 3)}
			</div>
			<div className="quarter q4 oside">
				{points.slice((points.length / 4) * 3)}
			</div>
			<button
				className={
					'border xfree ' +
					(long === 'free' || short === 'free' ? 'valid-dest' : '')
				}
				aria-label={'player free ' + props.game.board.freeYo}
				title={short === 'free' ? '[-]' : long === 'free' ? '[=]' : ''}
				onClick={(e) => {
					e.stopPropagation()
					makeMove(source!, 'free')
				}}
			>
				<Checkers count={props.game.board.freeYo}></Checkers>
			</button>
			<button
				aria-label={'opponent free ' + props.game.board.freeTu}
				className="border ofree"
			>
				<Checkers count={props.game.board.freeTu}></Checkers>
			</button>
			<div className="rail">{rail}</div>
			<div className="border rhs">
				<div>
					{props.readonly ? (
						<div className="face icon hidden"></div>
					) : (
						<button
							className="face icon"
							title="Settings [,]"
							onClick={props.onSettings}
						>
							<IconSettings />
						</button>
					)}
				</div>
				<div>
					{cantMove && !props.isAutoplay && !props.readonly ? (
						props.hasRolled ? (
							<button
								className="face icon"
								title="Submit [Enter]"
								onClick={props.onSubmit}
							>
								<IconCheck />
							</button>
						) : (
							<button
								className="face icon"
								title="Roll [Enter]"
								onClick={props.onRoll}
							>
								<IconPlay />
							</button>
						)
					) : (
						''
					)}
					{props.game.rolls.map((r, i) => (
						<Die key={i} value={r}></Die>
					))}
				</div>
				<div>
					{props.hasHistory && !props.readonly && !props.isAutoplay ? (
						<button
							className="face icon"
							title="Undo [U]"
							onClick={props.onRevert}
						>
							<IconUndo />
						</button>
					) : props.togglePrevState &&
					  !props.isAutoplay &&
					  !props.hasHistory ? (
						<button
							className="face icon"
							title="Show Previous [P]"
							onMouseDown={() => props.togglePrevState?.(true)}
							onMouseLeave={() => props.togglePrevState?.(false)}
							onTouchStart={() => props.togglePrevState?.(true)}
							onMouseUp={() => props.togglePrevState?.(false)}
							onTouchEnd={() => props.togglePrevState?.(false)}
						>
							<IconHistory />
						</button>
					) : (
						<div className="face icon hidden"></div>
					)}
				</div>
			</div>
			<div className="border top">{props.score}</div>
			<div className="border bottom" aria-live="polite">
				{props.message || ''}
			</div>
		</div>
	)
}
function buildRail(
	props: {
		game: GameState
		cubeEnabled?: boolean | undefined
		onCube?: (() => void) | undefined
	},
	source: string | number | undefined,
	selectJail: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
) {
	const cube = (
		<button
			key="dub"
			title="Double [D]"
			className={`doubling ${doublingCubeClasses(props.game)}`}
			onClick={props.onCube}
		>
			<Die value={doublingCubeValue(props.game.board)}></Die>
		</button>
	)

	const xjail = (
		<button
			key="xjail"
			aria-label={'player jail ' + props.game.board.jailYo}
			className={'border xjail ' + (source === 'jail' ? 'selected' : '')}
			onClick={selectJail}
		>
			<Checkers count={props.game.board.jailYo}></Checkers>
		</button>
	)
	const ojail = (
		<button
			key="ojail"
			aria-label={'opponent jail ' + props.game.board.jailTu}
			className={'border ojail ' + (source === 'jail' ? 'selected' : '')}
			onClick={selectJail}
		>
			<Checkers count={props.game.board.jailTu}></Checkers>
		</button>
	)

	const cubeLoc =
		props.game.board.doublingCube === 0
			? 'center'
			: props.game.board.doublingCube > 0 && props.game.board.player === 'X'
			? 'top'
			: props.game.board.doublingCube < 0 && props.game.board.player === 'O'
			? 'top'
			: 'bottom'

	const rail = []

	if (cubeLoc === 'top' && props.cubeEnabled) {
		rail.push(cube)
	}

	if (props.game.board.player === 'X') {
		rail.push(xjail)
	} else {
		rail.push(ojail)
	}

	if (cubeLoc === 'center' && props.cubeEnabled) {
		rail.push(cube)
	}

	if (props.game.board.player === 'O') {
		rail.push(xjail)
	} else {
		rail.push(ojail)
	}

	if (cubeLoc === 'bottom' && props.cubeEnabled) {
		rail.push(cube)
	}

	return rail
}

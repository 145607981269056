import React from 'react'
import { TPoint, TPlayer } from './Backgammon'

export const Checkers: React.FC<{ count: number }> = (props) => (
	<>
		{Array.from({ length: props.count }).map((_, i) => (
			<div className="checker" key={i}></div>
		))}
	</>
)

export const Point: React.FC<{
	point: TPoint
	player: TPlayer
	title: string
	index: number
	isSelected: boolean
	destType: 'short' | 'long' | undefined
	onSelected: () => void
}> = (props) => {
	const renderX = props.player === 'X' ? props.point > 0 : props.point < 0
	const isEmpty = props.point === 0
	const toRender = renderX ? 'X' : 'O'

	let classes = ['point']
	if (props.isSelected) {
		classes.push('selected')
	}
	if (isEmpty) {
		classes.push('empty')
	} else {
		if (toRender === 'X') {
			classes.push('xs')
		}
		if (toRender === 'O') {
			classes.push('os')
		}
	}
	if (props.destType) {
		classes.push('valid-dest')
	}
	classes.push(props.index % 2 === 0 ? 'dark' : 'light')
	const count = Math.abs(props.point)
	const label = `${props.index + 1}: ${
		count === 0 ? 'empty' : count + ' ' + (toRender === 'X' ? 'Green' : 'Blue')
	} ${props.title ? `select with ${props.title} or space` : ''}`

	return (
		<button
			className={classes.join(' ')}
			title={props.title}
			tabIndex={count ? 0 : -1}
			aria-label={label}
			onClick={(e) => {
				e.stopPropagation()
				props.onSelected()
			}}
		>
			<div className="backer"></div>
			<Checkers count={count}></Checkers>
		</button>
	)
}

import React from 'react'
import './die.css'

const Die: React.FC<{ value: number }> = ({ value }) => {
	if (!value) return <></>

	if (value <= 8) {
		return (
			<div tabIndex={0} aria-label={'die: ' + value} className="face">
				{Array.from({ length: value }).map((_, i) => (
					<span className="pip" key={i}></span>
				))}
			</div>
		)
	} else {
		return (
			<div tabIndex={0} aria-label={'die: ' + value} className="face icon">
				<span>{value}</span>
			</div>
		)
	}
}

export default Die

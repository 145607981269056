import React from 'react'
import {
	TMetadataRequest,
	TMetadataResponse,
	TResponse,
	TWorker,
} from './autoplayWorker'
import {
	Board as MakeBoard,
	TPlayer,
	FlipBoard,
	NextRolls,
	GameState,
	TMove,
	ApplyMove,
	SerializeBoard,
	SerializeGame,
	MatchState,
	BackGammon,
	TEnd,
	doublingCubeValue,
	TRoll,
} from './Backgammon'
import { Board } from './Board'
import { Config } from './Config'
import { Checkers } from './Point'

export type TOperator = 'human' | 'easy' | 'medium' | 'hard'
export type TConfig = {
	doubling: boolean
	playTill: number
	colorblind: boolean
	blue: TOperator
	green: TOperator
}

let taskNum = 0
const tasks: Map<number, (resolve: Partial<TResponse>) => void> = new Map()
const worker = new Worker(new URL('./autoplayWorker.ts', import.meta.url))
worker.onmessage = ({ data }: { data: TMetadataResponse }) => {
	const resolver = tasks.get(data.id)
	if (!resolver) {
		throw Error("oops, got a response I didn't expect")
	}
	resolver(data.response)
	tasks.delete(data.id)
}

const workerInterface: TWorker = (request) => {
	const id = taskNum++
	const metadataRequest: TMetadataRequest = { id, request }
	worker.postMessage(metadataRequest)
	return new Promise(async (r) => tasks.set(id, r as any))
}

type PlayerProps = {
	game?: GameState
}

type PlayerState = {
	match: MatchState
	game: GameState
	hasRolled: boolean
	history: GameState[][]
	players: Record<TPlayer, TOperator>
	showingPrev: boolean
	showingSettings: boolean
	colorblind: boolean
	movestring: string
	message: string
	log: string[]
}

export class BackGammonPlayer extends React.Component<
	PlayerProps,
	PlayerState
> {
	resizeInterval: number | undefined

	constructor(props: PlayerProps) {
		super(props)

		let config: TConfig | null = null
		try {
			config = JSON.parse(window.localStorage.getItem('config') ?? null!)
		} catch (e) {
			console.error('Error loading config from storage:', e)
		}
		config ??= {
			blue: 'human',
			green: 'human',
			doubling: false,
			colorblind: false,
			playTill: 1,
		}

		let rolls = props.game?.rolls
		if (!rolls) {
			rolls = this.roll(false, false)
		}

		this.state = {
			message: '',
			game: props.game ?? {
				board: MakeBoard({ player: rolls[0] > rolls[1] ? 'X' : 'O' }),
				rolls,
			},
			hasRolled: rolls.length > 0,
			history: [[]],
			players: { O: config.blue, X: config.green },
			match: {
				playingUntil: config.playTill,
				cubeEnabled: config.doubling,
				pointsX: 0,
				pointsO: 0,
			},
			showingPrev: false,
			showingSettings: false,
			colorblind: config.colorblind,
			movestring: '',
			log: [],
		}
	}

	private log = (message: string, allowDuplicate = true) => {
		this.setState(({ log }) => {
			if (allowDuplicate || message !== log[log.length - 1]) {
				console.log(message)
				return { log: [...log, message] }
			}
			return { log }
		})
	}

	private togglePrevState = (show: boolean) =>
		this.state.history[1] && this.setState({ showingPrev: show })

	render(): React.ReactNode {
		const score =
			this.state.match.playingUntil > 1 ? (
				<div className="match-score">
					<div className="score">
						<Checkers
							count={this.state.match.playingUntil - this.state.match.pointsX}
						></Checkers>
					</div>
					<div className="score playerX">
						<Checkers count={this.state.match.pointsX}></Checkers>
					</div>
					<div style={{ width: '15px' }}></div>
					<div className="score playerO">
						<Checkers count={this.state.match.pointsO}></Checkers>
					</div>
					<div className="score">
						<Checkers
							count={this.state.match.playingUntil - this.state.match.pointsO}
						></Checkers>
					</div>
				</div>
			) : (
				<></>
			)

		const gameToShow = this.state.showingPrev ? (
			<Board
				game={this.state.history[1][0]}
				readonly={true}
				cubeEnabled={this.state.match.cubeEnabled}
				togglePrevState={this.state.history[1] && this.togglePrevState}
				score={score}
			></Board>
		) : (
			<Board
				game={this.state.game}
				cubeEnabled={this.state.match.cubeEnabled}
				onCube={this.onCube}
				onRoll={this.doRoll}
				onClearMovestring={() => this.setState({ movestring: '' })}
				score={score}
				log={(s) => this.log(s, false)}
				movestring={this.state.movestring}
				hasRolled={this.state.hasRolled}
				hasHistory={this.state.history[0].length > 0}
				onSubmit={this.completeTurn}
				onRevert={this.onRevert}
				message={this.state.message || this.state.movestring}
				togglePrevState={this.state.history[1] && this.togglePrevState}
				onSettings={this.onToggleSettings}
				isAutoplay={
					this.state.players[this.state.game.board.player] !== 'human'
				}
				onMove={this.onMove}
			></Board>
		)

		const config = this.state.showingSettings ? (
			<Config
				onCancel={this.onToggleSettings}
				onSubmit={async (d) => {
					await this.setStateAsPromised((s) => ({
						players: {
							O: d.blue,
							X: d.green,
						},
						match: {
							pointsO: 0,
							pointsX: 0,
							playingUntil: d.playTill,
							cubeEnabled: d.doubling,
						},
						colorblind: d.colorblind,
					}))

					this.onToggleSettings()
					this.resetGame()
				}}
				config={{
					doubling: this.state.match.cubeEnabled,
					blue: this.state.players['O'],
					colorblind: this.state.colorblind,
					green: this.state.players['X'],
					playTill: this.state.match.playingUntil,
				}}
			/>
		) : (
			''
		)

		const log = (
			<main className="log" tabIndex={0}>
				<h2>Game Log</h2>
				<ol className="log" aria-live="polite">
					{this.state.log.map((e, i) => (
						<li key={i}>{e}</li>
					))}
				</ol>
			</main>
		)

		return (
			<div className={'player' + (this.state.colorblind ? ' colorblind' : '')}>
				{config}
				{log}
				<div tabIndex={0} aria-label={this.toString()} className="main">
					{gameToShow}
				</div>
			</div>
		)
	}

	private keyupListener = (e: KeyboardEvent) => {
		const keyMap: Record<string, () => void> = {
			p: () => this.togglePrevState(false),
		}
		keyMap[e.key]?.()
	}

	private keydownListener = (e: KeyboardEvent) => {
		if (e.repeat) return

		if (!isNaN(+e.key)) {
			this.setState(({ movestring }) => {
				if (movestring.length >= 2 || movestring.match(/-|=|\+/)) {
					movestring = ''
				}

				movestring += e.key
				return { movestring }
			})
		}

		const addModToMovestring = () =>
			this.setState(({ movestring }) => {
				if (movestring.length > 2 || movestring.match(/-|=/)) {
					movestring = ''
					return { movestring }
				} else {
					return {
						movestring: movestring + e.key,
					}
				}
			})

		const keyMap: Record<string, () => void> = {
			Enter: () => {
				if (this.state.hasRolled) {
					this.completeTurn()
				} else {
					this.doRoll()
				}
			},
			Backspace: () =>
				this.setState({ movestring: this.state.movestring.slice(0, -1) }),
			d: () => this.onCube(),
			p: () => this.togglePrevState(true),
			u: () => this.onRevert(),
			s: () => {
				const link = this.getLinkForGame(this.state.game)
				window.history.pushState({}, '', link)
				navigator.clipboard.writeText(link)
			},
			Escape: () => this.setState({ showingSettings: false, movestring: '' }),
			',': () =>
				this.setState(({ showingSettings }) => ({
					showingSettings: !showingSettings,
				})),
			'-': addModToMovestring,
			'+': addModToMovestring,
			'=': addModToMovestring,
		}

		keyMap[e.key]?.()
	}

	private getLinkForGame = (g: GameState) => {
		const link = new URL(window.location.href)
		link.searchParams.set('g', SerializeGame(g))
		return link.toString()
	}

	private onRevert = async () => {
		const turnHistory = this.state.history[0]
		if (!turnHistory.length) return
		const prev = turnHistory.pop()!
		this.log(`reverted move`)
		await this.setStateAsPromised({
			game: prev,
			history: [turnHistory, ...this.state.history.slice(1)],
		})
	}

	private onResize = () => {
		const width = window.innerWidth
		const scale = Math.min(width / 500, 2)
		document.body.style.setProperty('--ggs', scale + '')
	}

	private onMove = async (move: TMove) => {
		const newGame = ApplyMove(this.state.game, move)
		if (!newGame) {
			console.error('invalid move?', this.state.game, move)
		} else {
			const board = this.state.game.board
			const displaySource =
				move.source === 'jail'
					? 'jail'
					: board.player === 'X'
					? move.source + 1
					: board.points.length - move.source

			const displayTarget =
				move.target === 'free'
					? 'free'
					: board.player === 'X'
					? move.target + 1
					: board.points.length - move.target
			this.log(
				`moved ${displaySource} to ${displayTarget}. ${
					move.killer ? 'killed' : ''
				} ${
					newGame.rolls.length === 0 &&
					this.state.players[board.player] === 'human'
						? 'Enter to submit, U to undo'
						: ''
				}`,
			)
			const turnHistory = [...this.state.history[0], this.state.game]
			await this.setStateAsPromised({
				history: [turnHistory, ...this.state.history.slice(1)],
				movestring: '',
				game: newGame,
			})
		}
	}

	private onToggleSettings = () => {
		this.setState({ showingSettings: !this.state.showingSettings })
	}

	private onAutoplay = async () => {
		const level = this.state.players[this.state.game.board.player]
		if (level === 'human') {
			return
		}
		let didDouble = false
		if (!this.state.hasRolled) {
			if (
				this.state.match.cubeEnabled &&
				this.state.game.board.doublingCube <= 0
			) {
				this.setState({ message: 'Opponent Contemplating Double' })
				const { shouldRequestDouble } = await workerInterface({
					shouldRequestDouble: {
						config: { level },
						board: this.state.game.board,
						match: this.state.match,
					},
				})
				this.setState({ message: '' })
				if (shouldRequestDouble) {
					didDouble = await this.onCube()
					if (!didDouble) return
				}
			}
			if (!didDouble) {
				await this.doRoll()
			}
		}

		const patience = new Promise((resolve) => setTimeout(resolve, 1000))

		this.setState({ message: 'Opponent Contemplating Move' })

		const { findBestMove } = await workerInterface({
			findBestMove: {
				board: this.state.game.board,
				rolls: this.state.game.rolls,
				config: { level },
			},
		})
		console.log({ findBestMove })

		const nextRolls = NextRolls(this.state.game)
		const bestRoll = nextRolls.find(
			(r) => SerializeBoard(r.board) === SerializeBoard(findBestMove),
		)

		if (!bestRoll) return

		for (const move of bestRoll.moves) {
			await new Promise<void>((c) => setTimeout(() => c(), 500))
			await this.onMove(move)
		}
		await patience
		this.completeTurn()
	}

	private roll = (allowDoubles: boolean, log = true): TRoll => {
		const roll = () => Math.ceil(Math.random() * 6)
		let rolls: TRoll = []
		const isDouble = () => rolls[0] === rolls[1]

		do {
			rolls = [roll(), roll()]
		} while (!allowDoubles && isDouble())

		if (isDouble()) {
			rolls.push(rolls[0], rolls[0])
		}
		if (log) {
			this.log(`rolled ${rolls.join(' ')}`)
		}
		return rolls
	}

	private onCube = async () => {
		if (this.state.game.board.doublingCube > 0) {
			return true
		} else if (this.state.hasRolled) {
			return true
		} else {
			const shouldDouble = await this.requestDouble()
			if (shouldDouble) {
				await this.setStateAsPromised((p) => ({
					...p,
					game: {
						...p.game,
						board: {
							...p.game.board,
							doublingCube: -p.game.board.doublingCube + 1,
						},
					},
				}))
				await this.doRoll()
				return true
			} else {
				await this.terminateGame({
					score: doublingCubeValue(this.state.game.board),
					winner: this.state.game.board.player,
				})
				return false
			}
		}
	}

	private requestDouble = async () => {
		const otherPlayer =
			this.state.players[this.state.game.board.player === 'X' ? 'O' : 'X']

		if (otherPlayer !== 'human') {
			this.setState({ message: 'Opponent Contemplating Double' })
			const { shouldAcceptDouble } = await workerInterface({
				shouldAcceptDouble: {
					board: this.state.game.board,
					match: this.state.match,
					config: { level: otherPlayer },
				},
			})
			this.setState({ message: '' })
			return shouldAcceptDouble
		} else {
			return window.confirm('Double?')
		}
	}

	private terminateGame = async (end: TEnd) => {
		await this.setStateAsPromised((p) => ({
			hasRolled: false,
			match: {
				...p.match,
				pointsX: p.match.pointsX + (end.winner === 'X' ? end.score : 0),
				pointsO: p.match.pointsO + (end.winner === 'O' ? end.score : 0),
			},
		}))

		if (this.state.match.pointsX >= this.state.match.playingUntil) {
			alert('Green Wins!')
			this.resetMatch()
		}

		if (this.state.match.pointsO >= this.state.match.playingUntil) {
			alert('Blue Wins!')
			this.resetMatch()
		}

		await this.resetGame()
	}

	private resetMatch = () => {
		this.setState(({ match }) => ({
			match: {
				cubeEnabled: match.cubeEnabled,
				playingUntil: match.playingUntil,
				pointsO: 0,
				pointsX: 0,
			},
		}))
	}

	private resetGame = async () => {
		const rolls = this.roll(false)

		await this.setStateAsPromised({
			message: '',
			game: this.props.game ?? {
				rolls,
				board: MakeBoard({
					player: rolls[0] > rolls[1] ? 'X' : 'O',
				}),
			},
			hasRolled: true,
			history: [[]],
		})

		if (this.state.players[this.state.game.board.player] !== 'human') {
			this.onAutoplay()
		}
	}

	private completeTurn = async () => {
		const nextRolls = NextRolls(this.state.game)

		if (nextRolls.length === 0 || nextRolls[0].moves.length === 0) {
			const turnHistory = this.state.history[0]
			turnHistory.push(this.state.game)
			const endState = new BackGammon(this.state.game.board).getEndState()
			if (endState) {
				await this.terminateGame(endState)
			} else {
				await this.setStateAsPromised({
					message: '',
					hasRolled: false,
					game: {
						rolls: [],
						board: FlipBoard(this.state.game.board),
					},
					history: [[], ...this.state.history],
				})
				if (
					!this.state.match.cubeEnabled ||
					this.state.game.board.doublingCube > 0
				) {
					await this.doRoll()
				} else {
					this.setState({ message: 'Double or Roll' })
				}
			}

			if (this.state.players[this.state.game.board.player] !== 'human') {
				this.onAutoplay()
			}
		}

		this.log(this.toString(), false)
	}

	private doRoll = async () => {
		if (this.state.hasRolled || this.state.game.rolls.length) {
			throw Error('attempted to discard rolls')
		}

		await this.setStateAsPromised({
			hasRolled: true,
			message: '',
			game: {
				rolls: this.roll(true),
				board: this.state.game.board,
			},
		})
	}

	private toString = () => {
		const rollString = this.state.hasRolled
			? this.state.game.rolls.length +
			  ' dice remain: ' +
			  this.state.game.rolls.join(' ')
			: 'dice not rolled'

		const matchString =
			this.state.match.pointsO || this.state.match.pointsX
				? `playing till ${this.state.match.playingUntil} green: ${this.state.match.pointsX} blue: ${this.state.match.pointsO}`
				: ''

		const doublingCubeString = this.state.match.cubeEnabled
			? (this.state.game.board.doublingCube > 0
					? 'our'
					: this.state.game.board.doublingCube < 0
					? 'thier'
					: '') +
			  ' doubling cube ' +
			  doublingCubeValue(this.state.game.board)
			: ''

		const compontents = [
			this.state.game.board.player === 'X' ? 'green' : 'blue',
			this.state.message,
			rollString,
			matchString,
			doublingCubeString,
		]

		return compontents.join(';')
	}

	componentDidMount() {
		window.addEventListener('keyup', this.keyupListener)
		window.addEventListener('keydown', this.keydownListener)
		window.addEventListener('resize', this.onResize)
		this.onResize()

		this.log(this.toString(), false)

		// PWA's don't always get the resize event 🤷‍♀️
		this.resizeInterval = window.setInterval(() => this.onResize(), 5000)

		if (this.state.players[this.state.game.board.player] !== 'human') {
			this.onAutoplay()
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.keyupListener)
		window.removeEventListener('keydown', this.keydownListener)
		window.removeEventListener('resize', this.onResize)
		if (this.resizeInterval) {
			window.clearInterval(this.resizeInterval)
			this.resizeInterval = undefined
		}
	}

	private setStateAsPromised<K extends keyof PlayerState>(
		state:
			| ((
					prevState: Readonly<PlayerState>,
					props: Readonly<PlayerProps>,
			  ) => Pick<PlayerState, K> | PlayerState | null)
			| (Pick<PlayerState, K> | PlayerState | null),
	): Promise<void> {
		return new Promise((c) => this.setState(state, c))
	}
}

import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BackGammonPlayer } from './Player'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { DeserializeGame } from './Backgammon'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const query = new URLSearchParams(window.location.search)
const gameString = query.get('g')
const game = gameString ? DeserializeGame(gameString) : undefined

root.render(
	<React.StrictMode>
		<BackGammonPlayer game={game} />
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

import React from 'react'
import { TConfig, TOperator } from './Player'

export const Config: React.FC<{
	config: TConfig
	onSubmit: (data: TConfig) => void
	onCancel: () => void
}> = (props) => {
	return (
		<div className="configBackdrop" onClick={() => props.onCancel()}>
			<div className="configBorder">
				<div className="configBackground">
					<form
						className="config"
						id="config"
						onClick={(e) => e.stopPropagation()}
						onSubmit={(e) => {
							e.preventDefault()
							const form = Object.fromEntries(
								new FormData(e.target as HTMLFormElement),
							)
							const data: TConfig = {
								blue: form['blue'] as TOperator,
								green: form['green'] as TOperator,
								doubling: form['doubling'] === 'on',
								colorblind: form['colorblind'] === 'on',
								playTill: +form['playTill'],
							}
							window.localStorage.setItem('config', JSON.stringify(data))
							props.onSubmit(data)
						}}
					>
						<h2 className="configItem">Settings</h2>
						<div className="configItem">
							<label htmlFor="playTill">Play Until</label>
							<div id="playTill">
								<label>
									<input
										defaultChecked={props.config.playTill === 1}
										type="radio"
										name="playTill"
										value="1"
									/>
									1
								</label>
								<label>
									<input
										defaultChecked={props.config.playTill === 3}
										type="radio"
										name="playTill"
										value="3"
									/>
									3
								</label>
								<label>
									<input
										defaultChecked={props.config.playTill === 5}
										type="radio"
										name="playTill"
										value="5"
									/>
									5
								</label>
								<label>
									<input
										defaultChecked={props.config.playTill === 10}
										type="radio"
										name="playTill"
										value="10"
									/>
									10
								</label>
							</div>
						</div>
						<label className="configItem">
							<div className="fixwidth">Doubling Cube</div>
							<input
								defaultChecked={props.config.doubling}
								type="checkbox"
								name="doubling"
								id="doubling"
							/>
						</label>
						<label className="configItem">
							<div className="fixwidth">Color Blind</div>
							<input
								defaultChecked={props.config.colorblind}
								type="checkbox"
								name="colorblind"
								id="colorblind"
							/>
						</label>
						<div className="configItem">
							<label className="fixwidth" htmlFor="blue-select">
								Blue Player
							</label>
							<select
								defaultValue={props.config.blue}
								name="blue"
								id="blue-select"
							>
								<option value="human">Human</option>
								<option value="easy">🤖: Easy</option>
								<option value="medium">🤖: Medium</option>
								<option value="hard">🤖: Hard</option>
							</select>
						</div>
						<div className="configItem">
							<label className="fixwidth" htmlFor="green-select">
								Green Player
							</label>
							<select
								defaultValue={props.config.green}
								name="green"
								id="green-select"
							>
								<option value="human">Human</option>
								<option value="easy">🤖: Easy</option>
								<option value="medium">🤖: Medium</option>
								<option value="hard">🤖: Hard</option>
							</select>
						</div>
						<div className="configItem">
							<input type="submit" value="Save & Restart" />
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}
